import { Toast } from 'vant'
// 全局数据方法
export default {
  //  对象
  getQuestionType: {
    1: '单选题',
    2: '多选题',
    3: '不定项选择题',
    4: '判断题',
    5: '填空题',
    6: '问答题',
    7: '材料分析题',
    8: '配伍题'
  },
  // 层次
  getEduCategory: {
    0: '专升本',
    1: '高起本',
    2: '高起专',
    3: '中专',
    4: '高职',
    5: '专科',
    6: '本科'
  },
  // 0专升本 1高起本 2高起专
  getEduCategoryType: {
    0: '专升本',
    1: '高起本',
    2: '高起专',
    3: '中专',
    4: '高职',
    5: '专科',
    6: '本科'
  },
  // 0本科 1本科 2高起专
  getEduCategoryType2: {
    0: '本科',
    1: '本科',
    2: '大专'
  },
  // 学籍状态(0在读 1已毕业 2待录取)
  getStudentStatusType: {
    1: '已毕业',
    2: '待录取',
    0: '在读'
  },
  // 学习形式(0函授1脱产2业余3全日制)
  getStuType: {
    1: '脱产',
    2: '业余',
    0: '函授',
    3: '全日制'
  },
  // 注册状态(0未注册,1已注册)
  getRegistrationStatus: {
    1: '已注册',
    0: '未注册'
  },
  // 性别(0男,1女)
  sexList: {
    0: '男',
    1: '女'
  },

  // 民族
  nationData: [
		{id:1 ,name:'汉族'},
		{id:2 ,name:'蒙古族'},
		{id:3 ,name:'回族'},
		{id:4 ,name:'藏族'},
		{id:5 ,name:'维吾尔族'},
		{id:6 ,name:'苗族'},
		{id:7 ,name:'彝族'},
		{id:8 ,name:'壮族'},
		{id:9 ,name:'布依族'},
		{id:10,name:'朝鲜族'},
		{id:11,name:'满族'},
		{id:12,name:'侗族'},
		{id:13,name:'瑶族'},
		{id:14,name:'白族'},
		{id:15,name:'土家族'},
		{id:16,name:'哈尼族'},
		{id:17,name:'哈萨克族'},
		{id:18,name:'傣族'},
		{id:19,name:'黎族'},
		{id:20,name:'傈僳族'},
		{id:21,name:'佤族'},
		{id:22,name:'畲族'},
		{id:23,name:'高山族'},
		{id:24,name:'拉祜族'},
		{id:25,name:'水族'},
		{id:26,name:'东乡族'},
		{id:27,name:'纳西族'},
		{id:28,name:'景颇族'},
		{id:29,name:'柯尔克孜族'},
		{id:30,name:'土族'},
		{id:31,name:'达翰尔族'},
		{id:32,name:'么佬族'},
		{id:33,name:'羌族'},
		{id:34,name:'布朗族'},
		{id:35,name:'撒拉族'},
		{id:36,name:'毛南族'},
		{id:37,name:'仡佬族'},
		{id:38,name:'锡伯族'},
		{id:39,name:'阿昌族'},
		{id:40,name:'普米族'},
		{id:41,name:'塔吉克族'},
		{id:42,name:'怒族'},
		{id:43,name:'乌孜别克族'},
		{id:44,name:'俄罗斯族'},
		{id:45,name:'鄂温克族'},
		{id:46,name:'德昂族'},
		{id:47,name:'保安族'},
		{id:48,name:'裕固族'},
		{id:49,name:'京族'},
		{id:50,name:'塔塔尔族'},
		{id:51,name:'独龙族'},
		{id:52,name:'鄂伦春族'},
		{id:53,name:'赫哲族'},
		{id:54,name:'门巴族'},
		{id:55,name:'珞巴族'},
		{id:56,name:'基诺族'}
  ],

  // 政治面貌
  politicsData: {
    0: "群众",
    1: "中共党员",
    2: "中共预备党员",
    3: "共青团员",
    4: "民革会员",
    5: "民盟盟员",
    6: "民建会员",
    7: "民进会员",
    8: "农工党党员",
    9: "致公党党员",
    10: "九三学社社员",
    11: "台盟盟员",
    12: "无党派民主人士",
  },

  // 审核状态(实名认证状态(0未验证 1待审核 2通过 3不通过))
  auditStatus: {
    0: "未验证",
    1: "审核中",
    2: "通过",
    3: "不通过",
    4: "已失效"
  },
  // 学籍确认
  schoolConfirmStatus: {
    0: "未确认",
    1: "已确认"
  },
  // 学籍状态
  stuConfirmStatus: {
    1: "已录取",
    2: "在读"
  },
  // 上下箭头图标地址
  icon: {
    down: require("@/assets/img/home/selectDown.png"),
  },

  // 方法

  /**
   * 将数字转成中文大写
   * @params num
   */
  toChinesNum (num) {
    const changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'] // changeNum[0] = "零"
    const unit = ['', '十', '百', '千', '万']
    num = parseInt(num)
    const getWan = (temp) => {
      const strArr = temp.toString().split('').reverse()
      let newNum = ''
      for (var i = 0; i < strArr.length; i++) {
        newNum = (i == 0 && strArr[i] == 0 ? '' : (i > 0 && strArr[i] == 0 && strArr[i - 1] == 0 ? '' : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i]))) + newNum
      }
      return newNum
    }
    const overWan = Math.floor(num / 10000)
    let noWan = num % 10000
    if (noWan.toString().length < 4) noWan = '0' + noWan
    return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num)
  },
  copytouch (e, s) {
    e.preventDefault = true
    console.log('长按了')
  },
  preventPaste () {
    Toast('禁止复制粘贴')
  },

}
