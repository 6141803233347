import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import loginHome from '../views/teacher/loginHome.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/schoolHome/index',
    children: [
      // 小官网
      {
        path: '/schoolHome/index',
        name: 'home',
        meta: {
          title: '官网'
        },
        component: () => import('../components/home/index.vue'),
        children: [
          {
            path: '/homePageList',
            name: 'homePageList',
            meta: {
              title: '通知公告'
            },
            component: () => import('../components/newsNotice/noticeAnnouncement.vue'),
            children: [
              {
                path: 'homePageDetails',
                name: 'homePageDetails',
                component: () => import('../components/newsNotice/noticeDetails.vue')
              }
            ]
          },
          {
            path: '/graduteInfo',
            name: 'graduteInfo',
            meta: {
              title: '毕业信息'
            },
            component: () => import('../components/home/graduteInfo.vue'),
            children: [
            ]
          }
        ]
      },
      // 我的成绩
      {
        path: '/achievement',
        name: 'achievement',
        meta: {
          title: '我的成绩'
        },
        component: () => import('../components/myGrades/achievement.vue')
      },
      // 课程成绩
      {
        path: '/courseAchievement',
        name: 'courseAchievement',
        meta: {
          title: '课程成绩'
        },
        component: () => import('../components/myGrades/courseAchievement.vue')
      },
      // 通知公告
      {
        path: '/noticeAnnouncement',
        name: 'noticeAnnouncement',
        meta: {
          title: '通知公告'
        },
        component: () => import('../components/newsNotice/noticeAnnouncement.vue'),
        children: [
          {
            path: 'noticeDetails',
            name: 'noticeDetails',
            component: () => import('../components/newsNotice/noticeDetails.vue')
          }
        ]
      },
      // 慕课学习
      {
        path: '/MKStudy',
        name: 'MKStudy',
        component: () => import('../components/MKStudy/index.vue')
      },
      // pdf预览
      {
        path: '/viewPdf',
        name: 'viewPdf',
        // meta: {
        //   title: ''
        // },
        component: () => import('../components/viewFile/viewPdf.vue')
      },
      // 考试题目
      {
        path: '/exam',
        name: 'exam',
        component: () => import('../components/exam/exam.vue')
      },
      // 课程作业
      {
        path: '/courseWorkDetails',
        name: 'courseWorkDetails',
        component: () => import('../components/course/courseWorkDetails.vue')
      },
      // 关于我们
      {
        path: '/aboutUs',
        name: 'aboutUs',
        meta: {
          title: '关于我们'
        },
        component: () => import('../components/about/aboutUs.vue')
      },
      // 用户协议
      {
        path: '/userAgreement',
        name: 'userAgreement',
        meta: {
          title: '用户协议'
        },
        component: () => import('../components/article/userAgreement.vue')
      },
      // 隐私条款
      {
        path: '/privacyClause',
        name: 'privacyClause',
        meta: {
          title: '隐私条款'
        },
        component: () => import('../components/article/privacyClause.vue')
      },
      // 个人信息收集清单
      {
        path: '/privacyChecklist',
        name: 'privacyChecklist',
        meta: {
          title: '个人信息收集清单'
        },
        component: () => import('../components/article/privacyChecklist.vue')
      },
      // 第三方信息共享清单
      {
        path: '/thirdPartyList',
        name: 'thirdPartyList',
        meta: {
          title: '第三方信息共享清单'
        },
        component: () => import('../components/article/thirdPartyList.vue')
      },
      // 下载页一
      {
        path: '/ordinaryDown',
        name: 'ordinaryDown',
        component: () => import('../components/downLoad/ordinaryDown.vue')
      },
      // 下载页二
      {
        path: '/detailedDown',
        name: 'detailedDown',
        component: () => import('../components/downLoad/detailedDown.vue')
      },
      // 新的下载页
      {
        path: '/downApp',
        name: 'downApp',
        component: () => import('../components/downLoad/downApp.vue')
      },
      // 帮助中心
      {
        path: '/helpCenter',
        name: 'helpCenter',
        component: () => import('../components/help/helpCenter.vue'),
        meta: {
          title: '帮助中心'
        },
        children: [
          {
            path: 'helpDetails',
            name: 'helpDetails',
            meta: {
              title: '文章详情'
            },
            component: () => import('../components/help/helpDetails.vue')
          }
        ]
      },
      // 个人资料
      {
        path: '/personalData',
        name: 'personalData',
        meta: {
          title: '个人资料'
        },
        component: () => import('../components/personal/personalData.vue')
      },
      // 毕业生登记表
      {
        path: '/graduateRegister',
        name: 'graduateRegister',
        meta: {
          title: '毕业生登记表'
        },
        component: () => import('../components/graduateRegister/graduateRegister.vue')
      },
      // 滑块验证页
      {
        path: '/sliderVerification',
        name: 'sliderVerification',
        component: () => import('../components/verification/sliderVerification.vue')
      },
      // 消息中心
      {
        path: '/messageCenter',
        name: 'messageCenter',
        meta: {
          title: '消息中心'
        },
        component: () => import('../components/messageCenter/messageCenter.vue')
      },
      // 消息详情
      {
        path: '/messageDetail',
        name: 'messageDetail',
        meta: {
          title: '消息详情'
        },
        component: () => import('../components/messageCenter/messageDetail.vue')
      },
      // 微信分享页
      {
        path: '/weChatShare',
        name: 'weChatShare',
        component: () => import('../components/share/weChatShare.vue')
      },
      // 专项练习
      {
        path: '/specialProject',
        name: 'specialProject',
        component: () => import('../components/questionBank/specialProject/index.vue')
      },
      // 真题、模拟考试
      {
        path: '/examPaper',
        name: 'examPaper',
        component: () => import('../components/questionBank/examPaper/examPaper.vue')
      },
      // 题目收藏、我的错题
      {
        path: '/collectPaper',
        name: 'collectPaper',
        component: () => import('../components/questionBank/collection/index.vue')
      },
      // 学生学籍确认
      {
        path: '/studentStatusConfirm',
        name: 'studentStatusConfirm',
        component: () => import('../components/studentStatusConfirm/index.vue')
      },
      // 学生学籍确认-录取
      {
        path: '/admitStuStatusConfirm',
        name: 'admitStuStatusConfirm',
        component: () => import('../components/studentStatusConfirm/admitStuStatusConfirm.vue')
      },
      // 申请人工审核
      {
        path: '/applyReview',
        name: 'applyReview',
        component: () => import('../components/manualAudit/applyReview.vue')
      },
      // 人工审核详情
      {
        path: '/auditDetails',
        name: 'auditDetails',
        component: () => import('../components/manualAudit/auditDetails.vue')
      },
      // 学生预报名
      {
        path: '/preRegistration',
        name: 'preRegistration',
        component: () => import('../components/preRegistration/index.vue')
      },
      // 公共直播间
      {
        path: '/commonLive',
        name: 'commonLive',
        component: () => import('../components/commonLive/index.vue')
      },
      // 直播作业
      {
        path: '/liveWork',
        name: 'liveWork',
        component: () => import('../components/course/liveWork/index.vue')
      },
      // 课堂作业
      {
        path: '/courseWork',
        name: 'courseWork',
        component: () => import('../components/course/courseWork/index.vue')
      },
      // 检测签到中专页
      {
        path: '/checkSign',
        name: 'checkSign',
        component: () => import('../components/sign/checkSign.vue')
      },
      // 签到页
      {
        path: '/signIn',
        name: 'signIn',
        component: () => import('../components/sign/signIn.vue')
      },
      // 扫码签到流程页
      {
        path: '/scanCodeSignProcedure',
        name: 'scanCodeSignProcedure',
        component: () => import('../components/sign/scanCodeSignProcedure.vue')
      },
      // 学历清查材料上传
      {
        path: '/materialUpload',
        name: 'materialUpload',
        component: () => import('../components/degree/materialUpload.vue')
      },
      // 个人学位申请
      {
        path: '/personDegree',
        name: 'personDegree',
        component: () => import('../components/degree/personDegree.vue')
      }
    ]
  },
  // 小官网
  // {
  //   path: "/schoolHome",
  //   name: "schoolHome",
  //   component: schoolHome,
  //   redirect: { path: "/schoolHome/index" },
  //   children: [
  //     {
  //       path: 'index',
  //       name: 'home',
  //       meta: {
  //         title: '官网'
  //       },
  //       component: () => import('../components/home/index.vue'),
  //       children: []
  //     },
  //     {
  //       path: 'schoolNews',
  //       name: 'homePageList',
  //       meta: {
  //         // title: '通知公告'
  //       },
  //       component: () => import('../components/newsNotice/noticeAnnouncement.vue'),
  //       children: [

  //       ]
  //     },
  //     {
  //       path: 'schoolIntroduction',
  //       name: 'homePageDetails',
  //       component: () => import('../components/newsNotice/noticeDetails.vue')
  //     }
  //   ]
  // },
  // 教师端-登录
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  // 登录首页入口
  {
    path: '/loginHome',
    name: 'loginHome',
    component: loginHome,
    redirect: '/stuBasicInfo',
    children: [
      // 教师端-学生基础信息
      {
        path: '/stuBasicInfo',
        name: 'stuBasicInfo',
        meta: {
          title: '学生基础信息'
        },
        component: () => import('../views/teacher/stuBasicInfo.vue')
      },
      // 教师端-直播管理
      {
        path: '/liveManage',
        name: 'liveManage',
        meta: {
          title: '直播管理'
        },
        component: () => import('../views/teacher/liveManage.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
